import Box from "components/Box";
import getResponsiveProps from "utils/getResponsiveProps";

function Badge({ children, size: sizeProp, withBorder, color, lineHeight, truncate, ...props }) {
  // TODO - look at supporting similar props to the Button / RoundedTab
  // size, color, withBorder

  const sizes = {
    xs: {
      fontSize: 9,
      px: 20,
      py: "8.85px",
      lineHeight
    },
    sm: {
      fontSize: 9,
      px: 16,
      py: "10.85px",
      lineHeight
    },
    md: {
      fontSize: 12,
      px: 20,
      py: "9.3px",
      lineHeight
    },
    lg: {
      fontSize: 12,
      px: 18,
      py: "15.8px",
      lineHeight
    },
    xl: {
      fontSize: 12,
      px: 20,
      py: "19.8px",
      lineHeight
    }
  };

  let sizeProps = getResponsiveProps(sizes, sizeProp);

  if (withBorder) {
    props = {
      ...props,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: color
    };
  }

  if (truncate) {
    props = {
      ...props,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    };
  }

  return (
    <Box {...sizeProps} color={color} {...props}>
      {children}
    </Box>
  );
}

Badge.defaultProps = {
  display: "inline-block",
  // alignItems: "center",
  // justifyContent: "center",
  textTransform: "uppercase",
  borderRadius: 100,
  fontFamily: "gilroyBold",
  letterSpacing: "1px",
  "data-component-name": "Badge",
  size: "sm",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  lineHeight: "70%"
};

export default Badge;
