import { useLocation, useHistory } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import Box from "components/Box";
import Circle from "components/Circle";
import ProductFilters from "components/ProductFilters";
import { theme } from "core/theme";
import { WEBINAR_CONNECTIONS_QUERY } from "graphql/knowledgeCenter/webinars";
import { AREA_OF_INTEREST_FIELDS } from "graphql/shared";
import { useDebounce } from "hooks/useDebounce";
import useListControls from "hooks/useListControls";
import PlayIcon from "images/PlayIcon";
import EmptySearch from "tpo/EmptySearch";
import Group from "tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "tpo/InfiniteScroll";
import SortMenu from "tpo/SortMenu";
import Stack from "tpo/Stack";
import { Search } from "tpo/TextInput";
import useProductFilters from "tpo/shop/hooks/useProductFilters";

import OrganisationTabs from "../OrganisationTabs";
import WebinarCard from "./WebinarCard";

const FILTERS_QUERY = gql`
  query FiltersQuery($includeFilters: [IncludeFilters]) {
    sampleTypes
    testingServices
    categories: areasOfInterest(includeFilters: $includeFilters) {
      ...AreaOfInterestFields
    }
  }
  ${AREA_OF_INTEREST_FIELDS}
`;

export default function Webinars() {
  const {
    data: { sampleTypes = [], testingServices = [], categories: _categories = [] } = {}
  } = useQuery(FILTERS_QUERY, {
    variables: {
      includeFilters: ["Both", "B2C"]
    }
  });

  const categories = _categories.map(cat => cat.name);

  const { toggleFilter, allFilters, selectedFilters, validatedFilters } = useProductFilters({
    defaultFilters: {
      categories: [],
      sampleTypes: [],
      testingServices: []
    },
    allFilters: {
      testingServices: [...testingServices].sort(),
      categories: [...categories].sort(),
      sampleTypes: [...sampleTypes].sort()
    }
  });

  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search).get("search") || "";
  const debouncedSearch = useDebounce(search, 200);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Title asc",
        value: "title"
      },
      {
        label: "Title desc",
        value: "-title"
      },
      {
        label: "Start time asc",
        value: "start_time"
      },
      {
        label: "Start time desc",
        value: "-start_time"
      }
    ]
  });

  const { data, loading, fetchMore } = useQuery(WEBINAR_CONNECTIONS_QUERY, {
    variables: {
      first: 10,
      orderBy: controls.sort,
      search: debouncedSearch,
      testingServices: validatedFilters.testingServices.join(","),
      areasOfInterest: validatedFilters.categories.join(","),
      sampleTypes: validatedFilters.sampleTypes.join(",")
    }
  });

  const hasMore = data?.webinarConnections.pageInfo.hasNextPage;
  const endCursor = data?.webinarConnections.pageInfo.endCursor;
  const webinars = data?.webinarConnections.edges.map(edge => edge.node);

  return (
    <>
      <OrganisationTabs selectedTab="learning_hub" />
      <Stack
        gap={[40]}
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        maxWidth={1138}
        mx="auto"
        px={20}
      >
        <Box>
          <Stack gap={20}>
            <Group gap={20} alignItems="center">
              <Circle bg="transparent" size={80} border="2px solid black">
                <PlayIcon fill={theme.colors.dark} size={40} />
              </Circle>
              <Box fontFamily="gilroyBold" fontSize={[36, 36, 68]}>
                Webinars
              </Box>
            </Group>
            <Box fontFamily="gilroyMedium" fontSize={18}>
              Sign up to upcoming or re-watch our full archive of webinar sessions.{" "}
            </Box>
          </Stack>
          <Stack gap={20} pt={[40, 40, 80]}>
            <Box
              display="flex"
              flexDirection={["column", "column", "row"]}
              gap={20}
              justifyContent={[null, null, "space-between"]}
              flexWrap="wrap"
            >
              <Group gap={10}>
                <ProductFilters
                  allFilters={allFilters}
                  filtersTitle="Webinar Filters"
                  drawerBg="dark"
                  validatedFilters={validatedFilters}
                  toggleFilter={toggleFilter}
                />
              </Group>
              <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
                <Search
                  value={search}
                  onChange={s => {
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set("search", s);
                    history.push({
                      path: location.path,
                      search: searchParams.toString()
                    });
                  }}
                  maxWidth={[null, null, 350]}
                  minWidth={310}
                  width="100%"
                />
                <Box display="flex" justifyContent="flex-end">
                  <SortMenu
                    value={controls.sort}
                    setValue={controls.setSort}
                    open={controls.sortMenuOpen}
                    setOpen={controls.setSortMenuOpen}
                    options={controls.sortOptions}
                    placeholder="Sort By"
                  />
                </Box>
              </Box>
            </Box>
            {selectedFilters}
          </Stack>
          <Stack gap={16} pt={40} pb={theme.spacing.section.pb}>
            {!loading && !webinars?.length && <EmptySearch message="No webinars found" />}
            <InfiniteScroll
              hasMore={hasMore}
              next={() =>
                fetchMore({
                  variables: {
                    after: endCursor,
                    first: 10
                  }
                })
              }
              loading={loading}
            >
              <InfiniteScrollContext.Consumer>
                {({ setBottomElement }) => {
                  function renderWebinarCard(webinar, ref) {
                    return (
                      <WebinarCard
                        key={webinar.id}
                        title={webinar.title}
                        description={webinar.shortDescription}
                        host={webinar.host}
                        startTime={webinar.startTime}
                        endTime={webinar.endTime}
                        joinUsUrl={webinar.joinUsUrl}
                        id={webinar.id}
                        videoUrl={webinar.videoUrl}
                        ref={ref}
                        slug={webinar.slug}
                      />
                    );
                  }

                  return (
                    <>
                      {webinars?.slice(0, -1)?.map(webinar => renderWebinarCard(webinar))}
                      {webinars
                        ?.slice(-1)
                        ?.map(webinar => renderWebinarCard(webinar, setBottomElement))}
                    </>
                  );
                }}
              </InfiniteScrollContext.Consumer>
            </InfiniteScroll>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
