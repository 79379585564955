import { gql } from "@apollo/client";

export const WEBINAR_FIELDS = gql`
  fragment WebinarFields on WebinarType {
    id
    title
    slug
    shortDescription
    description
    startTime
    endTime
    registerNowUrl
    videoUrl
    testProduct {
      id
      name
      paymentIsoCode
      organisation
      laboratory {
        id
        name
        logoUrl
      }
    }
    host {
      id
      firstName
      lastName
      fullName
      avatarUrl
      title
      occupation
    }
  }
`;

export const WEBINAR_QUERY = gql`
  query WebinarQuery($id: ID, $slug: String) {
    webinar(id: $id, slug: $slug) {
      ...WebinarFields
    }
  }
  ${WEBINAR_FIELDS}
`;

export const WEBINAR_CONNECTIONS_QUERY = gql`
  query WebinarConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $testingServices: String
    $areasOfInterest: String
    $sampleTypes: String
    $upcoming: Boolean
  ) {
    webinarConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      testingServices: $testingServices
      areasOfInterest: $areasOfInterest
      sampleTypes: $sampleTypes
      upcoming: $upcoming
    ) {
      edges {
        node {
          ...WebinarFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${WEBINAR_FIELDS}
`;
